import React, { useEffect, useRef, useState } from 'react';

const YieldAggregatorLandingPage: React.FC = () => {
  const [visibleItems, setVisibleItems] = useState<number[]>([]);
  const roadmapRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [hasScrolled, setHasScrolled] = useState<boolean>(false);
  const aboutRef = useRef<HTMLElement>(null);
  const visionRef = useRef<HTMLElement>(null);
  const roadmapRef = useRef<HTMLElement>(null);
  const contactRef = useRef<HTMLElement>(null);

  const roadmapItems = [
    { phase: 'Genesis', description: '23Q4: Protocol Design and Smart Contract Development', icon: '🚀' },
    { phase: 'Ignition', description: '24Q3: Security Audits and Testnet Deployment', icon: '🔐' },
    { phase: 'Liftoff', description: '24Q4: Beta Launch and Community Testing', icon: '🌠' },
    { phase: 'Orbit', description: '25Q1: Mainnet Launch and Expansion to Multiple Chains', icon: '🌍' }
  ];

  const visionItems = [
    {
      title: 'Seamless Multi-Chain Yield',
      description: 'Effortlessly maximize returns across various blockchain networks with our intelligent protocols. We will offer a diverse range of strategy vaults to cater to different preferences, from Degen to Conservative.',
      icon: '🌐'
    },
    {
      title: 'User-Friendly Experience',
      description: 'Simplifying complex DeFi operations into intuitive interfaces for users of all experience levels. Smoothly and effortlessly invest funds from multiple chains into our All-in-One strategy vault.',
      icon: '🤝'
    },
    {
      title: 'Transparent and Secure Protocols',
      description: 'Building trust through open-source development and rigorous security measures. All contracts will undergo thorough audits, and a modular permission design will be implemented to minimize risk exposure.',
      icon: '🛡️'
    }
  ];

  useEffect(() => {
    const handleScroll = (event: WheelEvent) => {
      if (!hasScrolled && event.deltaY > 0) {
        event.preventDefault();
        setHasScrolled(true);
        if (aboutRef.current) {
          smoothScrollTo(aboutRef.current);
        }
      }
    };

    window.addEventListener('wheel', handleScroll, { passive: false });

    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
  }, [hasScrolled]);

  useEffect(() => {
    const observers = roadmapRefs.current.map((ref, index) => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setVisibleItems(prev => [...prev, index]);
            observer.unobserve(entry.target);
          }
        },
        { threshold: 0.5 }
      );
      if (ref) observer.observe(ref);
      return observer;
    });

    return () => observers.forEach(observer => observer.disconnect());
  }, []);

  const smoothScrollTo = (element: HTMLElement) => {
    const headerHeight = 80; // Adjust this value if your header height changes
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerHeight;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  };

  const handleNavClick = (event: React.MouseEvent<HTMLAnchorElement>, ref: React.RefObject<HTMLElement>) => {
    event.preventDefault();
    if (ref.current) {
      smoothScrollTo(ref.current);
    }
  };

  return (
    <div className="bg-gradient-to-br from-purple-900 to-indigo-900 text-white min-h-screen">
      <header className="fixed top-0 left-0 right-0 z-50 bg-opacity-50 bg-black h-20">
        <div className="container mx-auto px-4 h-full flex justify-between items-center">
          <div className="text-2xl font-bold">cha.money</div>
          <nav className="hidden md:flex space-x-6">
            <a href="#about" className="hover:text-purple-300" onClick={(e) => handleNavClick(e, aboutRef)}>About</a>
            <a href="#vision" className="hover:text-purple-300" onClick={(e) => handleNavClick(e, visionRef)}>Vision</a>
            <a href="#roadmap" className="hover:text-purple-300" onClick={(e) => handleNavClick(e, roadmapRef)}>Roadmap</a>
            <a href="#contact" className="hover:text-purple-300" onClick={(e) => handleNavClick(e, contactRef)}>Contact</a>
          </nav>
        </div>
      </header>

      <main className="pt-20">
        <section className="h-screen flex items-center justify-center py-16"> {/* 增加了 py-16 来提供更多的垂直空间 */}
          <div className="text-center px-4 space-y-12"> {/* 使用 space-y-12 来增加子元素之间的垂直间距 */}
            <h1 className="text-5xl md:text-6xl font-bold">CHA: The Future of DeFi Yield Optimization</h1>
            <p className="text-xl md:text-2xl max-w-3xl mx-auto">
              Revolutionizing yield aggregation across multiple chains. <br />
              Coming soon to maximize your crypto returns.</p>
            <div> {/* 将按钮包裹在一个 div 中，以便更好地控制间距 */}
              <button className="bg-purple-500 hover:bg-purple-600 px-8 py-4 rounded-full text-lg font-semibold transition-all duration-300 transform hover:scale-105">
                Join Waitlist
              </button>
            </div>
          </div>
        </section>

        <section id="about" ref={aboutRef} className="py-20 bg-indigo-800 bg-opacity-30">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold mb-6">About CHA</h2>
            <p className="text-lg mb-4">
              CHA is an innovative yield aggregation protocol currently under development. Our mission is to streamline the DeFi experience by providing a comprehensive solution for enhancing yields across multiple chains and protocols.<br />
              Our unique approach allows you to consolidate assets from different chains into a unified strategy pool on another chain. Unlike pseudo-cross-chain solutions that depend on a single bridge, we offer an almost seamless on-chain experience through collaborations with various inter-chain communication protocol partners.
            </p>
          </div>
        </section>

        <section id="vision" ref={visionRef} className="py-20">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold mb-10 text-center">Our Vision</h2>
            <div className="grid md:grid-cols-3 gap-8">
              {visionItems.map((item, index) => (
                <div key={item.title} className="relative group">
                  <div className="absolute inset-0 bg-gradient-to-r from-purple-600 to-indigo-600 rounded-lg transform rotate-6 group-hover:rotate-0 transition-transform duration-300"></div>
                  <div className="relative bg-gray-900 p-6 rounded-lg transform group-hover:-translate-y-2 transition-transform duration-300">
                    <div className="text-4xl mb-4">{item.icon}</div>
                    <h3 className="text-xl font-semibold mb-2">{item.title}</h3>
                    <p className="text-gray-300">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section id="roadmap" ref={roadmapRef} className="py-20 bg-indigo-800 bg-opacity-30">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold mb-10 text-center">Journey</h2>
            <div className="relative">
              <div className="absolute left-1/2 transform -translate-x-1/2 h-full w-1 bg-purple-500"></div>

              {roadmapItems.map((item, index) => (
                <div
                  key={item.phase}
                  className={`mb-20 flex ${index % 2 === 0 ? 'justify-start' : 'justify-end'}`}
                  ref={el => roadmapRefs.current[index] = el}
                >
                  <div className={`relative w-1/2 ${index % 2 === 0 ? 'pr-12' : 'pl-12'}`}>
                    <div className={`
                      bg-indigo-800 bg-opacity-50 p-6 rounded-lg shadow-lg 
                      transition-all duration-1000 ease-in-out 
                      ${visibleItems.includes(index) ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}
                    `}>
                      <div
                        className={`
                          absolute top-6 text-4xl
                          ${index % 2 === 0 ? '-right-32' : '-left-32'} 
                          transition-all duration-1000 delay-500 ease-in-out
                          ${visibleItems.includes(index) ? 'opacity-100 scale-100' : 'opacity-0 scale-50'}
                        `}
                      >
                        {item.icon}
                      </div>
                      <h3 className="text-2xl font-bold mb-2">
                        {item.phase}
                      </h3>
                      <p>
                        {item.description}
                      </p>
                    </div>
                    <div className={`
                      absolute top-6 w-6 h-6 rounded-full bg-purple-500 border-4 border-indigo-900 
                      ${index % 2 === 0 ? '-right-3' : '-left-3'} 
                      transition-all duration-1000 delay-300 ease-in-out
                      ${visibleItems.includes(index) ? 'opacity-100 scale-100' : 'opacity-0 scale-0'}
                    `}></div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section id="contact" ref={contactRef} className="py-20">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-3xl font-bold mb-6">Stay Updated</h2>
            <p className="mb-8">Join our community to receive updates on our progress and be the first to know when we launch. <br/>You can also touch us via team@cha.money</p>
            <div className="flex justify-center space-x-6">
              <a
                href="https://x.com/protocol_cha"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-blue-600 hover:bg-blue-700 px-6 py-2 rounded-full transition-colors duration-300 inline-block"
              >
                Twitter
              </a>
              <a
                href="https://discord.gg/BmKtsFptQV"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-indigo-600 hover:bg-indigo-700 px-6 py-2 rounded-full transition-colors duration-300 inline-block"
              >
                Discord
              </a>
            </div>
          </div>
        </section>
      </main>

      <footer className="bg-indigo-900 py-8">
        <div className="container mx-auto px-4 text-center">
          <p>&copy; 2024 cha.money. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default YieldAggregatorLandingPage;